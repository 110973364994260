<script>
import AdminTitle from "@/components/AdminTitle.vue";
import MainLogo from "@/components/MainLogo.vue";
import SecondaryButton from "@/components/SecondaryButton.vue";

export default {
	props: {
		text: {
			type: String,
			required: true,
		},
		textBold: {
			type: String,
			required: true,
		},
		action: {
			type: String,
			required: true,
		},
	},
	components: {
		AdminTitle,
		MainLogo,
		SecondaryButton
	},
	methods: {
		toggleForm() {
			this.$emit("toggleForm");
		},
	},
};
</script>

<template>
	<form class="main-form gradient-gray py-10 px-5 shadow-sm mb-3 border border-gray">
		<div
			class="container m-auto relative flex flex-col md:flex-row justify-between items-center"
		>
			<AdminTitle :text="text" :textBold="textBold" />
			<MainLogo class="md:absolute md:right-1/2 transform md:translate-x-1/2" />
			<secondary-button @click.native="toggleForm" :text="action" />
		</div>
	</form>
</template>

<style lang="scss" scoped></style>
